var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-row',{staticClass:"pb-1",attrs:{"no-gutters":"","align-v":"center"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"SettingsIcon"}}),_c('h4',{staticClass:"mb-0"},[_vm._v("Deal settings")])],1),_c('hr',{staticClass:"dropdown-divider mb-2"}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":"Number of days before a deal is marked as idle"}},[_c('label',[_vm._v("Deal Rotting")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Deal Rotting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"15"},model:{value:(_vm.dealRotting),callback:function ($$v) {_vm.dealRotting=$$v},expression:"dealRotting"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Pipeline")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Pipeline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.pipeline},model:{value:(_vm.defaultPipeline),callback:function ($$v) {_vm.defaultPipeline=$$v},expression:"defaultPipeline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Stage")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Stage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.stage},model:{value:(_vm.defaultStage),callback:function ($$v) {_vm.defaultStage=$$v},expression:"defaultStage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Deal Owner")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Deal Owner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.dealOwner},model:{value:(_vm.defaultDealOwner),callback:function ($$v) {_vm.defaultDealOwner=$$v},expression:"defaultDealOwner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }