<template>
  <validation-observer ref="simpleRules">
    <b-row no-gutters align-v="center" class="pb-1">
      <feather-icon icon="SettingsIcon" class="mr-25" />
      <h4 class="mb-0">Deal settings</h4>
    </b-row>
    <hr class="dropdown-divider mb-2" />
    <b-form>
      <b-row>

        <!--Deal Rotting-->
        <b-col md="6">
          <b-form-group description="Number of days before a deal is marked as idle">
            <label>Deal Rotting</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Deal Rotting"
            >
              <b-form-input
                v-model="dealRotting"
                :state="errors.length > 0 ? false:null"
                placeholder="15"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Default Pipeline-->
        <b-col md="6">
          <b-form-group>
            <label>Default Pipeline</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Pipeline"
            >
              <v-select
                v-model="defaultPipeline"
                :state="errors.length > 0 ? false:null"
                :options="pipeline"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Default Stage-->
        <b-col md="6">
          <b-form-group>
            <label>Default Stage</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Stage"
            >
              <v-select
                v-model="defaultStage"
                :state="errors.length > 0 ? false:null"
                :options="stage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Default Deal Owner-->
        <b-col md="6">
          <b-form-group>
            <label>Default Deal Owner</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Deal Owner"
            >
              <v-select
                v-model="defaultDealOwner"
                :state="errors.length > 0 ? false:null"
                :options="dealOwner"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit button -->
        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from "bootstrap-vue";
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      dealRotting: "",
      pipeline: ["Sales", "Delivery", "Prospecting"],
      defaultPipeline: "Sales",
      stage: [
        "Qualification - Delivery",
        "Needs Analysis - Delivery",
        "Value Proposition - Devlivery",
        "Decision Makers - Delivery",
        "Untouched - Sales",
        "First Visit - Sales",
        "Proposal Prepared - Sales",
        "Quote Sent - Sales",
        "Confirm Terms - Sales",
      ],
      defaultStage: "Qualification - Delivery",
      dealOwner: ["Super Admin", "Ali", "Mahmoud", "Ahmed"],
      defaultDealOwner: "Super Admin",
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      });
    },
  },
};
</script>
