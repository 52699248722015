<template>
  <b-sidebar
    id="source-sidebar"
    :visible="isSourceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-source-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Sources</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider #default="validationContext" name="Source">
            <b-form-group label="Source" label-for="source">
              <b-form-input
                id="source"
                v-model="source"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="e.g Facebook"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- draggable -->
          <draggable
            v-model="list"
            tag="ul"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(item, index) in list"
              :key="index"
              tag="li"
              class="mb-1 list-group-item"
            >
              <strong> {{ item }}</strong>
              <div class="d-flex">
                <div
                  :id="item"
                  class="cursor-pointer d-inline-flex"
                  @click="showPopover($event)"
                >
                  <feather-icon
                    ref="button"
                    class="prevent-click cursor-pointer"
                    icon="EditIcon"
                    size="16"
                  />
                </div>
                <feather-icon
                  v-b-modal.modal-center-source
                  class="ml-50 cursor-pointer"
                  icon="XIcon"
                  size="16"
                />
              </div>
            </b-list-group-item>
          </draggable>

          <b-modal
            id="modal-center-source"
            centered
            title="Confirm"
            ok-only
            ok-title="Ok"
          >
            <b-card-text> Do you want to delete this message? </b-card-text>
          </b-modal>

          <b-popover
            ref="popover"
            target="Google Ads"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Google Ads</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Google Ads"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Facebook"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Facebook</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Facebook"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Google Organic"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Google Organic</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Google Organic"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Web"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Web</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Web"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Twitter"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Twitter</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Twitter"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Client Referral"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Client Referral</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Client Referral"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <b-popover
            ref="popover"
            target="Youtube"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>Source - Youtube</span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Source"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  :state="input1state"
                  size="sm"
                  placeholder="Youtube"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk"
              >
                Ok
              </b-button>
            </div>
          </b-popover>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BListGroupItem,
  BModal,
  VBModal,
  BCardText,
  BPopover,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";

export default {
  name: "Source",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BListGroupItem,
    BModal,
    BCardText,
    BPopover,

    draggable,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  model: {
    prop: "isSourceSidebarActive",
    event: "update:is-source-sidebar-active",
  },
  props: {
    isSourceSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      source: "",
      list: [
        "Google Ads",
        "Facebook",
        "Google Organic",
        "Web",
        "Twitter",
        "Client Referral",
        "Youtube",
      ],
      input1: "",
      input1state: null,
      input1Return: "",
      popoverShow: false,
    };
  },
  watch: {
    input1(val) {
      if (val) {
        this.input1state = true;
      }
    },
  },
  methods: {
    showPopover(event) {
      this.onClose();
      this.$root.$emit("bv::show::popover", event.target.id);
    },
    onClose() {
      this.$root.$emit("bv::hide::popover");
    },
    onOk() {
      if (!this.input1) {
        this.input1state = false;
        this.onClose();

        // Return our popover form results
        this.input1Return = this.input1;
      }
    },
  },
  setup(props, { emit }) {
    const resetSource = "";

    const onSubmit = () => {
      emit("update:is-source-sidebar-active", false);
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetSource);

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style scoped>
.list-group-item {
  border: 1px solid #3b4253 !important;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}
.list-group-flush > .list-group-item:last-child {
  margin-bottom: 0 !important;
}
.prevent-click {
  pointer-events: none;
}
</style>
