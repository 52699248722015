<template>
  <div>
    <stages-sidebar :is-stages-sidebar-active.sync="isStagesSidebarActive" />
    <statuses-sidebar
      :is-statuses-sidebar-active.sync="isStatusesSidebarActive"
    />
    <source-sidebar :is-source-sidebar-active.sync="isSourceSidebarActive" />
    <validation-observer ref="simpleRules">
      <b-row no-gutters align-v="center" class="pb-1">
        <b-col md="4" class="d-flex">
          <feather-icon icon="SettingsIcon" class="mr-25 mt-25" />
          <h4 class="mb-0">Lead settings</h4>
        </b-col>
        <b-col md="8" class="d-flex" style="justify-content: right">
          <b-button variant="primary" @click="isStagesSidebarActive = true"
            >Stages</b-button
          >
          <b-button
            variant="primary"
            class="ml-50 mr-50"
            @click="isStatusesSidebarActive = true"
            >Statuses</b-button
          >
          <b-button variant="primary" @click="isSourceSidebarActive = true"
            >Source</b-button
          >
        </b-col>
      </b-row>
      <hr class="dropdown-divider mb-2" />
      <b-form>
        <b-row>
          <!--Default Stage-->
          <b-col md="6">
            <b-form-group>
              <label>Default Stage</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Default Stage"
              >
                <v-select
                  v-model="defaultStage"
                  :state="errors.length > 0 ? false : null"
                  :options="stage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Lead Won Stage Default-->
          <b-col md="6">
            <b-form-group>
              <label>Lead Won Stage Default</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Won Stage Default"
              >
                <v-select
                  v-model="defaultWonStage"
                  :state="errors.length > 0 ? false : null"
                  :options="wonStage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Lead Lost Stage Default-->
          <b-col md="6">
            <b-form-group>
              <label>Lead Lost Stage Default</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Lost Stage Default"
              >
                <v-select
                  v-model="defaultLostStage"
                  :state="errors.length > 0 ? false : null"
                  :options="lostStage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Leads Email-->
          <b-col md="6">
            <b-form-group>
              <label>Leads Email</label>
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Leads Email"
              >
                <b-form-input
                  v-model="leadsEmail"
                  v-b-tooltip.hover.top="'Email used to contact leads'"
                  :state="errors.length > 0 ? false : null"
                  placeholder="leads@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Leads Email Name-->
          <b-col md="6">
            <b-form-group>
              <label>Leads Email Name</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Leads Email Name"
              >
                <b-form-input
                  v-model="leadsEmailName"
                  v-b-tooltip.hover.top="
                    'Name that displays when you send email to leads'
                  "
                  :state="errors.length > 0 ? false : null"
                  placeholder="Acme Marketing"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Next Follow Up-->
          <b-col md="6">
            <b-form-group description="Number of days to follow up on a lead">
              <label>Auto Next Follow Up In</label>
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Next Follow Up"
              >
                <b-form-input
                  v-model="nextFollowUp"
                  :state="errors.length > 0 ? false : null"
                  placeholder="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Expiry Date-->
          <b-col md="6">
            <b-form-group
              description="Number of days before a lead is marked as expired"
            >
              <label>Expiry Date</label>
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Expiry Date"
              >
                <b-form-input
                  v-model="expiryDate"
                  :state="errors.length > 0 ? false : null"
                  placeholder="14"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Select Default Lead View-->
          <b-col md="12">
            <hr class="dropdown-divider mb-2" />
            <h3>Lead Default View</h3>
            <b-form-group>
              <label>Select Default Lead View</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Select Default Lead View"
              >
                <v-select
                  v-model="defaultLeadView"
                  :state="errors.length > 0 ? false : null"
                  :options="leadView"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <h3>Lead View (kanban / show) Settings</h3>

            <b-form-checkbox
              checked="true"
              name="show-shipping-transaction"
              switch
            >
              Show Shipping Transaction in Kanaban view Lead Card
            </b-form-checkbox>
            <b-form-checkbox checked="true" name="show-related-deal" switch>
              Show Related Deal in Kanaban view Lead Card
            </b-form-checkbox>
            <b-form-checkbox checked="true" name="show-related-shipping" switch>
              Show Related Shipping Transaction Address in Kanaban view Lead
              Card
            </b-form-checkbox>
            <b-form-checkbox checked="true" name="show-products" switch>
              Show Products in Kanaban view Lead Card
            </b-form-checkbox>
            <b-form-checkbox checked="true" name="show-assigned-user" switch>
              Show Assigned User and Author in Kanaban view Lead Card
            </b-form-checkbox>

            <b-form-group>
              <label>Lead Auto Assign To Users</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Auto Assign To Users"
              >
                <v-select
                  v-model="leadAutoAssignToUsers"
                  :state="errors.length > 0 ? false : null"
                  :options="users"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label>CallRail Auto Assign To Users</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="CallRail Auto Assign To Users"
              >
                <v-select
                  v-model="callRailAutoAssignToUsers"
                  :state="errors.length > 0 ? false : null"
                  :options="callRail"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-checkbox checked="false" name="check-button" switch>
              Show (Convert To Deal) in Lead Convert Pop up
            </b-form-checkbox>

            <b-form-checkbox checked="false" name="check-button" switch>
              Show (Convert To Shipment) in Lead Convert Pop up
            </b-form-checkbox>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import StagesSidebar from "./StagesSidebar.vue";
import StatusesSidebar from "./StatusesSidebar.vue";
import SourceSidebar from "./SourceSidebar.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,
    StagesSidebar,
    StatusesSidebar,
    SourceSidebar,
  },
  setup() {
    const isStagesSidebarActive = ref(false);
    const isStatusesSidebarActive = ref(false);
    const isSourceSidebarActive = ref(false);

    return {
      isStagesSidebarActive,
      isStatusesSidebarActive,
      isSourceSidebarActive,
    };
  },
  data() {
    return {
      leadsEmail: "",
      leadsEmailName: "",
      nextFollowUp: "",
      expiryDate: "",
      stage: [
        "Won",
        "Lost",
        "Contact Later",
        "In Discussion",
        "Proposal Sent",
        "Contacted",
        "New Lead",
      ],
      defaultStage: "New Lead",
      wonStage: [
        "Won",
        "Lost",
        "Contact Later",
        "In Discussion",
        "Proposal Sent",
        "Contacted",
        "New Lead",
      ],
      defaultWonStage: "Won",
      lostStage: [
        "Won",
        "Lost",
        "Contact Later",
        "In Discussion",
        "Proposal Sent",
        "Contacted",
        "New Lead",
      ],
      defaultLostStage: "Lost",
      leadView: ["Table", "Kanban"],
      defaultLeadView: "Table",
      leadAutoAssignToUsers: "",
      users: ["User 1", "User2", "User3", "User4"],
      callRailAutoAssignToUsers: "",
      callRail: ["User 1", "User2", "User3", "User4"],
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    };
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line arrow-parens
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
  },
};
</script>
