var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-row',{staticClass:"pb-1",attrs:{"no-gutters":"","align-v":"center"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"SettingsIcon"}}),_c('h4',{staticClass:"mb-0"},[_vm._v("General settings")])],1),_c('hr',{staticClass:"dropdown-divider mb-2"}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Company Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"info@example.com"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Company Phone")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"(123) 456 78900"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Company Phone 2")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.secondePhoneNumber),callback:function ($$v) {_vm.secondePhoneNumber=$$v},expression:"secondePhoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Mobile")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"(123) 456 00000"},model:{value:(_vm.mobileNumber),callback:function ($$v) {_vm.mobileNumber=$$v},expression:"mobileNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Fax")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.faxNumber),callback:function ($$v) {_vm.faxNumber=$$v},expression:"faxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Website")]),_c('validation-provider',{attrs:{"name":"URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"https://ouija.com"},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Email Signature")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Email Signature"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"1234 Main Street, Anywhere, MA 01234, USA"},model:{value:(_vm.emailSignature),callback:function ($$v) {_vm.emailSignature=$$v},expression:"emailSignature"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Currency")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.currency},model:{value:(_vm.defaultCurrency),callback:function ($$v) {_vm.defaultCurrency=$$v},expression:"defaultCurrency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":"Overwritten by Client's Currency"}},[_c('label',[_vm._v("Currency Symbol")]),_c('validation-provider',{attrs:{"rules":"required","name":"Currency Symbol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.currencySymbol},model:{value:(_vm.defaultCurrencySymbol),callback:function ($$v) {_vm.defaultCurrencySymbol=$$v},expression:"defaultCurrencySymbol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Timezone")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Timezone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.timezone},model:{value:(_vm.defaultTimezone),callback:function ($$v) {_vm.defaultTimezone=$$v},expression:"defaultTimezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Default Language")]),_c('validation-provider',{attrs:{"rules":"required","name":"Default Language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.language},model:{value:(_vm.defaultLanguage),callback:function ($$v) {_vm.defaultLanguage=$$v},expression:"defaultLanguage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Locale")]),_c('validation-provider',{attrs:{"rules":"required","name":"Locale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.locale},model:{value:(_vm.defaultLocale),callback:function ($$v) {_vm.defaultLocale=$$v},expression:"defaultLocale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Thousand Separator")]),_c('validation-provider',{attrs:{"rules":"required","name":"Thousand Separator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.thousandSeparator},model:{value:(_vm.defaultThousandSeparator),callback:function ($$v) {_vm.defaultThousandSeparator=$$v},expression:"defaultThousandSeparator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Decimal Separator")]),_c('validation-provider',{attrs:{"rules":"required","name":"Decimal  Separator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.decimalSeparator},model:{value:(_vm.defaultDecimalSeparator),callback:function ($$v) {_vm.defaultDecimalSeparator=$$v},expression:"defaultDecimalSeparator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Quantity Decimals")]),_c('validation-provider',{attrs:{"rules":"required","name":"Quantity Decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.quantityDecimals},model:{value:(_vm.defaultQuantityDecimals),callback:function ($$v) {_vm.defaultQuantityDecimals=$$v},expression:"defaultQuantityDecimals"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Date Format")]),_c('validation-provider',{attrs:{"rules":"required","name":"Date Format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.dateFormat},model:{value:(_vm.defaultDateFormat),callback:function ($$v) {_vm.defaultDateFormat=$$v},expression:"defaultDateFormat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }