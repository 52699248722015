<template>
  <validation-observer ref="simpleRules">
    <b-row no-gutters align-v="center" class="pb-1">
      <feather-icon icon="SettingsIcon" class="mr-25" />
      <h4 class="mb-0">General settings</h4>
    </b-row>
    <hr class="dropdown-divider mb-2" />
    <b-form>
      <b-row>

        <!-- Company email -->
        <b-col md="6">
          <b-form-group>
            <label>Company Email</label>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                placeholder="info@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Company Phone-->
        <b-col md="6">
          <b-form-group>
            <label>Company Phone</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Number"
            >
              <b-form-input
                v-model="phoneNumber"
                :state="errors.length > 0 ? false:null"
                placeholder="(123) 456 78900"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Company Phone 2-->
        <b-col md="6">
          <b-form-group>
            <label>Company Phone 2</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Number"
            >
              <b-form-input
                v-model="secondePhoneNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Mobile-->
        <b-col md="6">
          <b-form-group>
            <label>Mobile</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Number"
            >
              <b-form-input
                v-model="mobileNumber"
                :state="errors.length > 0 ? false:null"
                placeholder="(123) 456 00000"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Fax-->
        <b-col md="6">
          <b-form-group>
            <label>Fax</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Number"
            >
              <b-form-input
                v-model="faxNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Website  -->
        <b-col md="6">
          <b-form-group>
            <label>Website</label>
            <validation-provider
              #default="{ errors }"
              name="URL"
              rules="required|url"
            >
              <b-form-input
                v-model="URL"
                :state="errors.length > 0 ? false:null"
                placeholder="https://ouija.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Default Email Signature-->
        <b-col md="6">
          <b-form-group>
            <label>Default Email Signature</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Email Signature"
            >
              <b-form-textarea
                v-model="emailSignature"
                :state="errors.length > 0 ? false:null"
                placeholder="1234 Main Street, Anywhere, MA 01234, USA"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Default Currency-->
        <b-col md="6">
          <b-form-group>
            <label>Default Currency</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Currency"
            >
              <v-select
                v-model="defaultCurrency"
                :state="errors.length > 0 ? false:null"
                :options="currency"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Currency Symbol-->
        <b-col md="6">
          <b-form-group description="Overwritten by Client's Currency">
            <label>Currency Symbol</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Currency Symbol"
            >
              <v-select
                v-model="defaultCurrencySymbol"
                :state="errors.length > 0 ? false:null"
                :options="currencySymbol"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Default Timezone-->
        <b-col md="6">
          <b-form-group>
            <label>Default Timezone</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Timezone"
            >
              <v-select
                v-model="defaultTimezone"
                :state="errors.length > 0 ? false:null"
                :options="timezone"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Default Language-->
        <b-col md="6">
          <b-form-group>
            <label>Default Language</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Default Language"
            >
              <v-select
                v-model="defaultLanguage"
                :state="errors.length > 0 ? false:null"
                :options="language"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Locale-->
        <b-col md="6">
          <b-form-group>
            <label>Locale</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Locale"
            >
              <v-select
                v-model="defaultLocale"
                :state="errors.length > 0 ? false:null"
                :options="locale"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Thousand Separator-->
        <b-col md="6">
          <b-form-group>
            <label>Thousand Separator</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Thousand Separator"
            >
              <v-select
                v-model="defaultThousandSeparator"
                :state="errors.length > 0 ? false:null"
                :options="thousandSeparator"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Decimal  Separator-->
        <b-col md="6">
          <b-form-group>
            <label>Decimal  Separator</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Decimal  Separator"
            >
              <v-select
                v-model="defaultDecimalSeparator"
                :state="errors.length > 0 ? false:null"
                :options="decimalSeparator"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Quantity Decimals-->
        <b-col md="6">
          <b-form-group>
            <label>Quantity Decimals</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Quantity Decimals"
            >
              <v-select
                v-model="defaultQuantityDecimals"
                :state="errors.length > 0 ? false:null"
                :options="quantityDecimals"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Date Format-->
        <b-col md="6">
          <b-form-group>
            <label>Date Format</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Date Format"
            >
              <v-select
                v-model="defaultDateFormat"
                :state="errors.length > 0 ? false:null"
                :options="dateFormat"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit button -->
        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea,
} from "bootstrap-vue";
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      name: "",
      passwordValue: "",
      passwordCon: "",
      emailValue: "",
      number: "",
      numberRange: "",
      numberRegx: "",
      URL: "",
      Alphabetic: "",
      digitValue: "",
      digitValue2: "",
      character: "",
      emailSignature: "",
      phoneNumber: "",
      secondePhoneNumber: "",
      mobileNumber: "",
      faxNumber: "",
      currency: ["Egyption Pound", "Dollars", "Euros"],
      defaultCurrency: "Egyption Pound",
      currencySymbol: ["ج.م. - Egyption Pound", "$ - Dollars", "€ - Euro"],
      defaultCurrencySymbol: "ج.م. - Egyption Pound",
      timezone: ["Africa/Cairo", "Africa/Dakar", "Africa/Canary"],
      defaultTimezone: "Africa/Cairo",
      language: ["English", "French", "German"],
      defaultLanguage: "English",
      locale: ["English - en", "French - fr", "German - de"],
      defaultLocale: "English - en",
      thousandSeparator: [",", "."],
      defaultThousandSeparator: ",",
      decimalSeparator: [",", "."],
      defaultDecimalSeparator: ".",
      quantityDecimals: ["0", "1", "2"],
      defaultQuantityDecimals: "2",
      dateFormat: [
        "12-01-2022",
        "01-12-2022",
        "2022-01-12",
        "2022.01.12",
        "12.01.2022",
        "01.12.2022",
        "12/01/2022",
        "12 Jan, 2022",
        "Jan 12, 2022",
      ],
      defaultDateFormat: "12 Jan, 2022",
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      });
    },
  },
};
</script>
