<template>
  <div id="settings">
    <b-card class="settings-card">
      <b-tabs id="settings" vertical nav-wrapper-class="nav-vertical">
        <b-tab active title="General settings">
          <general-settings />
        </b-tab>
        <b-tab title="Deal settings">
          <deal-settings />
        </b-tab>
        <b-tab title="Lead settings">
          <lead-settings />
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="p-1"></div>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";

import GeneralSettings from "./GeneralSettings.vue";
import DealSettings from "./DealSettings.vue";
import LeadSettings from "./leads-settings/LeadSettings.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    GeneralSettings,
    DealSettings,
    LeadSettings,
  },

  methods: {},
};
</script>

<style>
/*.settings-card {
  z-index: 1000000;
}
 #settings .tab-pane {
  border: 1px solid #3b4253;
  padding: 1rem;
} */
</style>
